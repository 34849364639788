import Home from "./Home/Home";
import BlogDetails from "./BlogDetails/BlogDetails";
import Category from "./Category/Category";

const Pages = {
    Home,
    BlogDetails,
    Category,
};

export default Pages;
