import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import { GiCricketBat } from "react-icons/gi";
import { GoStar } from "react-icons/go";
import OwlCarousel from "react-owl-carousel";
import Themes from "../../themes";

import moment from "moment";
import { TiSocialFacebookCircular, TiSocialInstagramCircular, TiSocialLinkedinCircular, TiSocialTwitterCircular, TiSocialYoutubeCircular } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import ScheduleCarousel from "../../Components/ScheduleCarousel/ScheduleCarousel";
import APIConfig from "../../Config/APIConfig";
import { getBlogList, getEditorList, getLatestCategory, getTrendingPlayer } from "../../Redux/action";
import "./Home.css";
import { categoryList, featuredArray, horizontalImageList, verticalImageList } from "./data/data";
import Footer from "../../Components/Footer/Footer";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { blogList, latestCategoryList, trendingPlayerLIst, editorList } = useSelector(state => state.user);
    /* Declare OwlCarousel option in variable*/
    const carouselOptions = {
        items: 3,
        margin: 15,
        padding: 20,
        nav: false,
        touchDrag: false,
        mouseDrag: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplaySpeed: 2000,
        slideTransition: 'linear',
        loop: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    const iconImage = [
        require("../../assets/Icon/cricketIcon.png"),
        require("../../assets/Icon/footballIcon.png"),
        require("../../assets/Icon/noto_tennis.png"),
    ]

    useEffect(() => {
        getBlogApi();
        dispatch(getLatestCategory());
        dispatch(getTrendingPlayer());
        dispatch(getEditorList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getBlogApi = async () => {
        try {
            await dispatch(getBlogList());
        } catch (error) {
            console.log(error, '---')
        }
    }

    return (
        <>
            <Header />

            <main className="min_layout">
                <section className="filter_section">
                    <div className="container">
                        <ul className="filter_list">
                            <li>
                                <button><GoStar /></button>
                            </li>
                            <li>
                                <button><GiCricketBat /></button>
                            </li>
                            <li>
                                <button className="active">FEATURED (10)</button>
                            </li>
                            <li>
                                <button>ALL (32)</button>
                            </li>
                            <li>
                                <button>LIVE (12)</button>
                            </li>
                            <li>
                                <button>SCHEDULED (12)</button>
                            </li>
                            <li>
                                <button>LIVE (18)</button>
                            </li>
                            <li>
                                <button>POSTPONED (2)</button>
                            </li>
                        </ul>
                    </div>
                </section>

                <ScheduleCarousel />

                <section className="top_news_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-header">TOP NEWS</div>
                                    <div className="card-body">
                                        {blogList && blogList.length > 0 &&
                                            <>
                                                <OwlCarousel  {...carouselOptions} className="news_carousel" items={1} responsive={{}}>
                                                    {blogList.map((item, index) => {
                                                        return (
                                                            <div className="row " key={index}>
                                                                <div className="col-md-6">
                                                                    <img className="news_img" src={item?.image} alt="" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="news_right">
                                                                        <h5 className="news_title" onClick={() => navigate(`/blog-detail/${item?.name}/${item?._id}`)}>{item?.name}</h5>
                                                                        {/* <div className="news_description" dangerouslySetInnerHTML={{ __html: item.description.slice(0, 200) || "" }}></div> */}
                                                                        {/* {item?.description?.length > 1000 ?
                                                                            <div className="news_description" dangerouslySetInnerHTML={{ __html: item?.description.slice(0, 950) + '...' }}></div>
                                                                            :
                                                                            <div className="news_description" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                                                        } */}
                                                                        <p className="news_description">{item?.short_description}</p>
                                                                        {item?.updatedAt ? <p className="news_time"><span>&#9679; </span>{moment(item?.updatedAt).fromNow()}</p> : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </OwlCarousel>

                                                {/* {blogList.length > 1 &&
                                                    <OwlCarousel  {...carouselOptions} className="news_carousel" items={4}>
                                                        {blogList.slice(1, blogList?.length).map((item, index) => {
                                                            return (
                                                                <Link to={`/blog-detail/${item?._id}`} key={String(index)} className="news_carousel_img" style={{ backgroundImage: `url("${item?.image}")` }}>
                                                                    <p>{item?.short_description}</p>
                                                                </Link>
                                                            );
                                                        })}
                                                    </OwlCarousel>
                                                } */}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body ad_card">
                                        <div>
                                            <img src="https://www.crictracker.com/_next/image/?url=https%3A%2F%2Fmedia.crictracker.com%2Fmedia%2Fattachments%2F1710510315969_Rajasthan-Royals.jpeg&w=576&q=75" alt="" />
                                            <p className="ad_txt">Advertisement</p>
                                            <h5>Get your shoes on and run for health </h5>
                                            <h6>Minimum 50% on on fitness shoes</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="latest_highlights_section">
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="title-II6">LATEST HIGHLIGHTS</h2>
                                <OwlCarousel {...carouselOptions} items={3} margin={0} nav={true} >
                                    {latestCategoryList.map((items, index) => {
                                        return (
                                            <div className="highlights_card" key={String(index)}>
                                                <h4 className="highlights_Count">{index + 1}</h4>
                                                <p onClick={() => navigate(`/blog-detail/${items?.short_description}/${items?._id}`)}>{items.short_description}</p>
                                            </div>
                                        );
                                    })}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="featured-leagues">
                                    <h2 className="title-II6">FEATURED LEAGUES</h2>
                                    <div className="row">
                                        {[...featuredArray].map((item, i) => {
                                            return (
                                                <div className="col-md-6 leagues-card" key={String(i)}>
                                                    <div className="card-header">
                                                        <h5 className="body-font3">{item?.title}</h5>
                                                        <img src={item?.icons} alt={`${item?.title}-icon`} className="icon-image" />
                                                    </div>
                                                    <div className="card-body">
                                                        {item?.content.map((item2, index) => {
                                                            return (
                                                                <div className="content" key={String(index)}>
                                                                    <img src={item2?.image} alt="icon" className="content-image" />
                                                                    <p>{item2?.name}</p>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                    <div className="card-footer">
                                                        <Link to={"/"} >See more</Link>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="editor-pick">
                                    <h2 className="title-II6">EDITOR’S PICK</h2>
                                    <div className="category">
                                        {categoryList.map((d, i) => {
                                            return (
                                                <button key={i} className={i === 0 ? "active" : "no-active"}>
                                                    {d}{i === 0 ? null : <img src={iconImage[i % iconImage.length]} alt="" />}
                                                </button>
                                            )
                                        })}
                                    </div>
                                    <div className="editor-slider">
                                        <OwlCarousel  {...carouselOptions} nav={true} margin={8}>
                                            {editorList.map((item, index) => {
                                                return (
                                                    <div onClick={() => navigate(`/blog-detail/${item?.short_description}/${item?._id}`)} style={{ backgroundImage: `url("${item?.image}")` }} key={index} className="slider-card">
                                                        <div className="slider-content">
                                                            <p>{item?.short_description}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </OwlCarousel>
                                    </div>
                                </div>

                                <div className="editor-pick international-feed">
                                    <h2 className="title-II6">INTERNATIONAL FEED</h2>
                                    <div className="category">
                                        {categoryList.map((d, i) => {
                                            return (
                                                <button key={String(i)} className={i === 0 ? "active" : "no-active"}>
                                                    {d}{i === 0 ? null : <img src={iconImage[i % iconImage.length]} alt="" />}
                                                </button>
                                            )
                                        })}
                                    </div>
                                    <div className="editor-slider">
                                        <OwlCarousel  {...carouselOptions} nav={true} margin={8}>
                                            {[...Array(10)].map((_, index) => {
                                                return (
                                                    <div style={{ backgroundImage: `url("${horizontalImageList[index % horizontalImageList.length]}")` }} key={index} className="slider-card">
                                                        <div className="slider-content">
                                                            <p>Ashwin: If I am under pressure then  try to put the opposition fives time more. </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="trending-players">
                                    <h2 className="title-II6">TRENDING PLAYERS </h2>
                                    <div className="row gy-4">
                                        {trendingPlayerLIst?.length > 0 && trendingPlayerLIst.map((item, index) => {
                                            return (
                                                <React.Fragment key={String(index)}>
                                                    <div className="col-lg-6">
                                                        <figure className="text-center">
                                                            {/* <img src={APIConfig.BASE_URL + 'uploads/player/' + item?.player?.[0]?.image} alt="" className="trending-player-image" /> */}
                                                            <img src={item?.player?.[0]?.image} alt="" className="trending-player-image" />
                                                            <figcaption className="fig-caps">
                                                                {item?.categories?.[0]?.name}
                                                            </figcaption>
                                                            <figcaption className="title-II6 fig-caps-name">
                                                                {item?.player?.[0]?.name}
                                                            </figcaption>
                                                        </figure>

                                                        <div className="details-section">
                                                            <div className="player-details">
                                                                <img src={require("../../assets/Icon/cricketIcon.png")} alt={`-icon`} className="icon-image" />
                                                                <p>{item.short_description}</p>
                                                            </div>
                                                            <Link to={`/blog-detail/${item?.short_description}/${item?._id}`}>
                                                                Learn more
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="most-shared">
                                    <h2 className="title-II6">MOST SHARED</h2>
                                    <div className="most-list">
                                        {[...Array(7)].map((_, index) => {
                                            return (
                                                <div className="most-list-card" key={index}>
                                                    <p className="sr-number">{index + 1}</p>
                                                    <p>Pant's return is huge, but so are Capitals' concerns in the pace department </p>
                                                    <FaChevronRight />
                                                </div>
                                            )
                                        })}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
};

export default Home;
