import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header'
import ScheduleCarousel from '../../Components/ScheduleCarousel/ScheduleCarousel'
import Footer from '../../Components/Footer/Footer'
import { IoMdCalendar } from "react-icons/io";
import { FaRegClock } from "react-icons/fa6";
import moment from 'moment'
import './CategoryStyle.css'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryDataApi } from '../../Redux/action';

const Category = (props) => {
    const dispatch = useDispatch();
    const { name, id } = useParams();
    const { categoryList } = useSelector(state => state.user);
    const [categoryData, setCategoryData] = useState([])

    useEffect(() => {
        if (id) {
            getCategoryData(id)
        }
    }, [name])

    const getCategoryData = async (id) => {
        try {
            await dispatch(getCategoryDataApi({ id: id }));
        } catch (error) {
            console.log(error, '---')
        }
    }

    return (
        <>
            <Header />

            <main className="min_layout">
                <ScheduleCarousel />

                <section className="category_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    {categoryList.map((data, index) => {
                                        return (
                                            <div className="col-md-4" key={index}>
                                                <div className="category_card">
                                                    <img src={data?.image} alt="img" className='category_img' />
                                                    <h4 className='category_title'>{data?.name}</h4>
                                                    <div className='category_time_row'>
                                                        <p>
                                                            <span><IoMdCalendar /></span>
                                                            {moment(data?.createdAt).format('Do MMM YYYY')}
                                                        </p>
                                                        <p>
                                                            <span><FaRegClock /></span>
                                                            2 min
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body ad_card">
                                        <div>
                                            <img src="https://www.crictracker.com/_next/image/?url=https%3A%2F%2Fmedia.crictracker.com%2Fmedia%2Fattachments%2F1710510315969_Rajasthan-Royals.jpeg&w=576&q=75" alt="" />
                                            <p className="ad_txt">Advertisement</p>
                                            <h5>Get your shoes on and run for health </h5>
                                            <h6>Minimum 50% on on fitness shoes</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export default Category