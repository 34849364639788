import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import ScheduleCarousel from '../../Components/ScheduleCarousel/ScheduleCarousel';
import APIConfig from '../../Config/APIConfig';
import { getBlogDetail } from '../../Redux/action';
import './BlogDetailsStyle.css';
import { useParams } from 'react-router-dom';

const BlogDetails = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { scheduleList, blogList } = useSelector(state => state.user);
    const [blogDetail, setBlogDetail] = useState({})

    useEffect(() => {
        getBlogDetailApi();
    }, [])

    const getBlogDetailApi = async () => {
        try {
            const res = await dispatch(getBlogDetail({ id: id }));
            if (res && res?.[0]) {
                setBlogDetail(res[0])
            }
        } catch (error) {
            console.log(error, '---')
        }
    }

    return (
        <>
            <Header />

            <main className="min_layout">

                <ScheduleCarousel />

                <section className="blog_detail_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="card">
                                    {/* <div className="card-header">{blogDetail.name}</div> */}
                                    <div className="card-body">
                                        {blogDetail &&
                                            <>
                                                <img className="blog_img" src={blogDetail.image} alt="" />

                                                <div className="blog_content">
                                                    {blogDetail?.updatedAt ? <p className="blog_time"><span>&#9679; </span>{moment(blogDetail.updatedAt).fromNow()}</p> : null}
                                                    <h5 className="blog_title">{blogDetail.name}</h5>
                                                    <div className="blog_description" dangerouslySetInnerHTML={{ __html: blogDetail?.description }}></div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body ad_card">
                                        <div>
                                            <img src="https://www.crictracker.com/_next/image/?url=https%3A%2F%2Fmedia.crictracker.com%2Fmedia%2Fattachments%2F1710510315969_Rajasthan-Royals.jpeg&w=576&q=75" alt="" />
                                            <p className="ad_txt">Advertisement</p>
                                            <h5>Get your shoes on and run for health </h5>
                                            <h6>Minimum 50% on on fitness shoes</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    )
}

export default BlogDetails