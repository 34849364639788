import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import Themes from "../../themes";

import { TiSocialFacebookCircular, TiSocialInstagramCircular, TiSocialLinkedinCircular, TiSocialTwitterCircular, TiSocialYoutubeCircular } from "react-icons/ti";
import { Link } from "react-router-dom";
import "./FooterStyle.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid col-11">
                <div className="row">
                    <div className="col-md-2">
                        <h3 className="title-II6">CRICKET</h3>
                        <ul>
                            <li><Link to="/">Schedule</Link></li>
                            <li><Link to="/">Results</Link></li>
                            <li><Link to="/">News</Link></li>
                            <li><Link to="/">Highlights</Link></li>
                            <li><Link to="/">WPL 2024</Link></li>
                            <li><Link to="/">IPL T20 2024</Link></li>
                            <li><Link to="/">PSL 2024</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h3 className="title-II6">FOOTBALL</h3>
                        <ul>
                            <li><Link to="/">Schedule</Link></li>
                            <li><Link to="/">Results</Link></li>
                            <li><Link to="/">News</Link></li>
                            <li><Link to="/">Highlights</Link></li>
                            <li><Link to="/">ISL 2024</Link></li>
                            <li><Link to="/">I-LEAGUE</Link></li>
                            <li><Link to="/">PEPL</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h3 className="title-II6">OTHER SPORTS</h3>
                        <ul>
                            <li><Link to="/">Tennis</Link></li>
                            <li><Link to="/">Badminton</Link></li>
                            <li><Link to="/">Formula 1</Link></li>
                            <li><Link to="/">Hockey</Link></li>
                            <li><Link to="/">Kabbadi</Link></li>
                            <li><Link to="/">Wrestling</Link></li>

                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h3 className="title-II6">MY ACCOUNT</h3>
                        <ul>
                            <li><Link to="/">Saved Sports</Link></li>
                            <li><Link to="/">Sign Up</Link></li>
                            <li><Link to="/">News</Link></li>

                        </ul>
                    </div>
                    <div className="col-md-4 footer-logo">
                        <div>
                            <Link to="/">
                                <img src={Themes.Images.WhiteLogo} alt="" className="nav_logo" />
                            </Link>
                            <div className="page-list">
                                <ul>
                                    <li><Link to="/"> About Us</Link></li>
                                    <li><Link to="/">Contact</Link></li>
                                    <li><Link to="/">Help</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/">Advertise</Link></li>
                                    <li><Link to="/">Feedback</Link></li>
                                    <li><Link to="/">Blog</Link></li>
                                </ul>
                            </div>
                            <ul className="social-container">
                                <li><Link to="/"><TiSocialFacebookCircular className="social-icon" /> </Link></li>
                                <li><Link to="/"><TiSocialTwitterCircular className="social-icon" /></Link></li>
                                <li> <Link to="/"><TiSocialInstagramCircular className="social-icon" /></Link></li>
                                <li><Link to="/"><TiSocialYoutubeCircular className="social-icon" /></Link></li>
                                <li><Link to="/"><TiSocialLinkedinCircular className="social-icon" /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <p className="footer-privacy-policy">Privacy Policy | Terms of Use | Ads Info | @2024 CricSquare, All rights reserved |</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;