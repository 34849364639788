import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useCallback, useEffect } from "react";
import { CiStar } from "react-icons/ci";
import { GiCricketBat } from "react-icons/gi";
import OwlCarousel from "react-owl-carousel";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import APIConfig from "../../Config/APIConfig";
import { getScheduleCarousel } from "../../Redux/action";
import "./ScheduleCarouselStyle.css";

/* Declare OwlCarousel option in variable*/
const carouselOptions = {
    items: 3,
    margin: 15,
    padding: 20,
    nav: false,
    touchDrag: false,
    mouseDrag: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 2000,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 4
        }
    }
};

const ScheduleCarousel = () => {
    const dispatch = useDispatch();
    const { scheduleList } = useSelector(state => state.user);
    useEffect(() => {
        getScheduleApi();
    }, [])

    const getScheduleApi = async () => {
        try {
            await dispatch(getScheduleCarousel());
        } catch (error) {
            console.log(error, '---')
        }
    }

    const _checkSchedule = useCallback((dateTime) => {
        const givenDate = moment(dateTime);
        const currentDate = moment();
        const yesterday = moment().subtract(1, 'days').startOf('day');
        if (givenDate.isSame(currentDate, 'd')) {
            return 'Live';
        } else if (givenDate.isBefore(currentDate, 'd')) {
            return 'Postponed';
        } else {
            return 'Upcoming';
        }
    }, [])

    return (
        <section className="match_carousel">
            <div className="container">
                {scheduleList && scheduleList.length > 0 &&
                    <OwlCarousel {...carouselOptions} nav={true} >
                        {scheduleList.map((item, index) => {
                            const schedule = _checkSchedule(item?.datetime);
                            const isUpcoming = item?._id == '664c88c1070992f133a3b854'
                            const isLive = item?._id == '664b0fdded72284f5dc99477'
                            return (
                                <div className="match_card" key={String(index)}>
                                    <div className="card_category_row">
                                        <div>
                                            <div className={`card_type ${!isLive && 'type_upcoming'}`}>
                                                {/* <p>{schedule}</p> */}
                                                <p>{isUpcoming ? 'Upcoming' : isLive ? 'live' : schedule}</p>
                                            </div>
                                            <h3 className="stadium_name">- {item?.matchnumber}</h3>
                                            <h2 className="stadium_name">- {item?.venue?.state?.name}</h2>
                                        </div>
                                        <h6><span>{item?.sport?.name}</span></h6>
                                    </div>
                                    <div className="match_title">
                                        <h3 className="match_type">{item?.event?.name}</h3>
                                    </div>

                                    <div className="team_Row">
                                        <div>
                                            <img src={item?.teamA?.logo ? `${APIConfig.BASE_URL}uploads/${item?.teamA.logo}` : ""} alt="" />
                                            <h3>{item?.teamA?.name}</h3>
                                        </div>
                                        {!isUpcoming &&
                                            <p className={isLive ? 'today' : ''}>{isLive ? '*196/4 (20 ov)' : ''}</p>
                                        }
                                    </div>
                                    <div className={`team_Row ${schedule !== 'Live' && 'upcoming_match'}`}>
                                        <div>
                                            <img src={item?.teamB?.logo ? `${APIConfig.BASE_URL}uploads/${item?.teamB?.logo}` : ""} alt="" />
                                            <h3>{item?.teamB?.name}</h3>
                                        </div>
                                        {!isUpcoming &&
                                            <p className="time_txt">{isLive ? 'Yet To Bat' : ''}</p>
                                        }
                                    </div>

                                    {/* <p className={`card_info ${schedule !== 'Live' && 'upcoming_info'}`}>{item?.venue?.stadium_name}</p> */}
                                    <p className={`card_info ${schedule !== 'Live' && 'upcoming_info'}`}>{isUpcoming ? moment(item?.datetime).format('LLLL') : item?.venue?.stadium_name}</p>
                                </div>
                            );
                        })}
                    </OwlCarousel>
                }
            </div>
        </section>
    )
}

export default React.memo(ScheduleCarousel);