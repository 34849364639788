import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoMenuSharp, IoSearch } from "react-icons/io5";
import Themes from "../../themes";

import "./HeaderStyle.css";

const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg sticky-top top_nav">
            <div className="container-fluid col-11">
                <Link className="navbar-brand " to='/'>
                    <img src={Themes.Images.WhiteLogo} alt="" className="nav_logo" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    {/* <span className="navbar-toggler-icon" /> */}
                    <IoMenuSharp className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0 nav_center">
                        <li className="nav-item">
                            <NavLink className="nav-link" aria-current="page" to={'/category/ipl/665efd7f40b61394b18890b3'} >
                                IPL
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/category/t20wordldcup/665efd9740b61394b18890b7'} >
                                T20 World Cup
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/category/frenchopen/665efda840b61394b18890bb'} >
                                French Open
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/category/parisolympics/665efdb340b61394b18890bf'} >
                                Paris Olympics
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link" href="#">
                                Hockey
                            </a>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                More
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Action
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Another action
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Something else here
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                    </ul>

                    <ul className="navbar-nav mb-2 mb-lg-0 nav_right">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">
                                <img src={Themes.Images.LanguageIcon} alt="" className="nav_icon" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <FaRegCircleUser className="nav_icon" />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                <IoSearch className="nav_icon" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header