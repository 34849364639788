import CryptoJS from "crypto-js";

/* Declaring CryptoJS Key */
const encryptedKey = "cfcfc159-hghgh456-3hgh4ge003-6refcg4789-hgfhf75rtdcgfcbv7531";

/**
 * Function of encryptData
 * 
 * @param {Object} data of you need to encrypt
 * @returns {string} encrypt String
 */
export const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), encryptedKey).toString();
};

/**
 * Function of decryptData
 * 
 * @param {Object} data of you need to decrypt
 * @returns {string} decrypt String
 */
export const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, encryptedKey);
    try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) { /* If localStorag are null */
        return null;
    }
};

/** 
 * Function of get data from LocalStorage
 * 
 * @function
 * @name decryptData for decryp data
 * @return data of decrypt
*/
export const getLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    if (data) {
        const result = decryptData(data);
        return result;
    }
    return null;
};

/** 
 * Function of set data to LocalStorage
 * 
 * @function
 * @name encryptData for decryp data
 * Set decryp string to LocalStorage
*/
export const setLocalStorage = (key, data) => {
    const encryptedData = encryptData(data);
    localStorage.setItem(key, encryptedData);
};

/**
 * Function for authentication login or not
 * 
 * @returns {Boolean}
 */
export const checkAuthExist = () => {
    const userData = getLocalStorage("user_info");
    if (userData && userData.token && userData.users.user_type === 'admin') {
        if (userData.token) {
            return true;
        }
        return false;
    }
    return false;
};