import APIConfig from "../Config/APIConfig";
import { APICall } from "../Helper/NetworkUtility";
import { CATEGORY_LIST, EDITOR_LIST, LATEST_CATEGORY_LIST, LOADER, SET_BLOG_LIST, SET_SCHEDULE_LIST, TRENDING_PLAYER_LIST } from "./constant";

/* ========================== Home Page Api Calling ========================= */
/**
 * Get Schedule Carousel list
 */
export const getScheduleCarousel = payload => {
    return dispatch => {
        return APICall('get', payload, APIConfig.EndPoints.schedule).then(
            result => {
                return dispatch({
                    type: SET_SCHEDULE_LIST,
                    payload: result?.data?.data || [],
                });
            },
            error => {
                return Promise.reject(error);
            },
        );
    };
};

/**
 * Get Blog list
 */
export const getBlogList = payload => {
    return dispatch => {
        return APICall('get', payload, APIConfig.EndPoints.blog).then(
            result => {
                return dispatch({
                    type: SET_BLOG_LIST,
                    payload: result?.data?.data || [],
                });
            },
            error => {
                return Promise.reject(error);
            },
        );
    };
};

/**
 * Get Blog Detail
 */
export const getBlogDetail = payload => {
    const { id, ...newPayload } = payload;
    return dispatch => {
        return APICall('get', newPayload, APIConfig.EndPoints.blog + '/' + id).then(
            result => {
                return result?.data?.data || []
            },
            error => {
                return Promise.reject(error);
            },
        );
    };
};

/**
 * Get Latest Category list
 */
export const getLatestCategory = payload => {
    return dispatch => {
        return APICall('get', payload, APIConfig.EndPoints.latestcategory + '/6649ed75ed72284f5dc992aa').then(
            result => {
                return dispatch({
                    type: LATEST_CATEGORY_LIST,
                    payload: result?.data?.data?.blogs || [],
                });
            },
            error => {
                return Promise.reject(error);
            },
        );
    };
};

/**
 * Get Trending Player List
 */
export const getTrendingPlayer = payload => {
    return dispatch => {
        return APICall('get', payload, APIConfig.EndPoints.trendingplayer).then(
            result => {
                return dispatch({
                    type: TRENDING_PLAYER_LIST,
                    payload: result?.data?.data || [],
                });
            },
            error => {
                return Promise.reject(error);
            },
        );
    };
};

/**
 * Get Trending Player List
 */
export const getEditorList = payload => {
    return dispatch => {
        return APICall('get', payload, APIConfig.EndPoints.latestcategory + '/6654914acafbbc386be64039').then(
            result => {
                return dispatch({
                    type: EDITOR_LIST,
                    payload: result?.data?.data?.blogs || [],
                });
            },
            error => {
                return Promise.reject(error);
            },
        );
    };
};

/**
 * Get Category Data
 */
export const getCategoryDataApi = payload => {
    const { id, ...newPayload } = payload;
    return dispatch => {
        dispatch({
            type: CATEGORY_LIST,
            payload: [],
        });
        return APICall('get', newPayload, APIConfig.EndPoints.latestcategory + '/' + id).then(
            result => {
                return dispatch({
                    type: CATEGORY_LIST,
                    payload: result?.data?.data?.blogs || [],
                });
            },
            error => {
                return Promise.reject(error);
            },
        );
    };
};

/**
 * Button indicator
 */
export const setLoader = value => {
    return (dispatch) => {
        dispatch({
            type: LOADER,
            payload: value,
        });
    };
};