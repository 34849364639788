import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Pages from "../Page";

function PageRouter() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Pages.Home />} />
                <Route exact path="/blog-detail/:name/:id" element={<Pages.BlogDetails />} />
                <Route exact path="/category/:name/:id" element={<Pages.Category />} />
            </Routes>
        </Router>
    );
}

export default PageRouter;
