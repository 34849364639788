export const featuredArray = [
    {
        title: "CRICKET",
        icons: require("../../../assets/Icon/cricketIcon.png"),
        content: [
            {
                name: "Big Bash League",
                image: require("../../../assets/Images/kfc.png")
            },
            {
                name: "Indian Premiere League",
                image: require("../../../assets/Images/ipl.png")
            },
            {
                name: "ICC World Cup",
                image: require("../../../assets/Images/T20.png")
            },

        ]
    },
    {
        title: "FOOTBALL",
        icons: require("../../../assets/Icon/footballIcon.png"),
        content: [
            {
                name: "ISL",
                image: require("../../../assets/Images/isl.png")
            },
            {
                name: "I-League",
                image: require("../../../assets/Images/ileague.png")
            },
            {
                name: "EPL",
                image: require("../../../assets/Images/pleague.png")
            },

        ]
    },
    {
        title: "TENNIS",
        icons: require("../../../assets/Icon/noto_tennis.png"),
        content: [
            {
                name: "ATP Miami",
                image: require("../../../assets/Images/atp.png")
            },
            {
                name: "WTA Miami",
                image: require("../../../assets/Images/wta.png")
            },


        ]
    },
    {
        title: "BADMINTON",
        icons: require("../../../assets/Icon/noto_tennis.png"),
        content: [
            {
                name: "BWF World Tour Men",
                image: require("../../../assets/Images/bwf.png")
            },
            {
                name: "BWF Grand Prix",
                image: require("../../../assets/Images/bwfgp.png")
            },


        ]
    },
]


export const categoryList = ["All", "Cricket", "Football", "Badminton", "Tennis", "More"]




export const horizontalImageList = [
    "https://images.unsplash.com/photo-1624193634221-33b652971323?q=80&w=1984&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1624194697120-34347cff8b58?q=80&w=2117&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1624526267942-ab0ff8a3e972?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1562077772-3bd90403f7f0?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1664303548011-317e1892e440?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1563184956-7a02a2852738?q=80&w=2097&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1540747913346-19e32dc3e97e?q=80&w=2010&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

]
export const verticalImageList = [
    "https://images.unsplash.com/photo-1643294357573-36f3ed94ce4c?q=80&w=1958&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1646963740850-8961423d1d7f?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1646963740704-091e10f5fcea?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1661858618085-bd4bd65a2369?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1661859240155-0e2e8c1d5138?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1661890097755-fc3ee6dccb85?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1565787112882-4e7d9d29269c?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1565787154274-c8d076ad34e7?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1565787113569-be8aa6f5da41?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1571801366823-ae2ea851a3de?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

]