const urls = {
    live: 'https://api.sportzsquare.net/',
    development: 'http://192.168.31.131:3000/',
    local: 'http://192.168.31.131:3000/',
};

export default {
    BASE_URL: `${urls.live}`, // Base URL
    API_URL: `${urls.live}api/`, // Base URL
    EndPoints: {
        schedule: 'schedule',
        blog: 'blog',
        latestcategory: 'latestcategory',
        trendingplayer: 'trendingplayer',
    },
};
