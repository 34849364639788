// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background: var(--color-primary-blue-5);
    padding: 47px 0;
}

.footer .title-II6 {
    color: var(--color-neutrals-neutrals-1);
    margin-bottom: 10px;
}

.footer a {
    color: var(--color-neutrals-neutrals-1);
    font-weight: 400;
    font-size: 14px;
}

.footer ul li {
    margin: 10px 0;
}


.footer .footer-logo {
    display: flex;
    justify-content: center;
}

.footer .footer-logo .nav_logo {
    height: 30px;
    width: auto;
}

.footer .footer-logo .page-list {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}


.footer a .social-icon {
    font-size: 32px;

}

.footer ul.social-container {
    display: flex;
    justify-content: space-between;
}

.footer ul.social-container a {
    display: block;
}

.footer .footer-privacy-policy {
    font-weight: 500;
    font-size: 10px;
    color: var(--color-neutrals-neutrals-1);
    padding-top: 47px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/FooterStyle.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,eAAe;AACnB;;AAEA;IACI,uCAAuC;IACvC,mBAAmB;AACvB;;AAEA;IACI,uCAAuC;IACvC,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;;AAGA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;AACrB;;;AAGA;IACI,eAAe;;AAEnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,uCAAuC;IACvC,iBAAiB;AACrB","sourcesContent":[".footer {\n    background: var(--color-primary-blue-5);\n    padding: 47px 0;\n}\n\n.footer .title-II6 {\n    color: var(--color-neutrals-neutrals-1);\n    margin-bottom: 10px;\n}\n\n.footer a {\n    color: var(--color-neutrals-neutrals-1);\n    font-weight: 400;\n    font-size: 14px;\n}\n\n.footer ul li {\n    margin: 10px 0;\n}\n\n\n.footer .footer-logo {\n    display: flex;\n    justify-content: center;\n}\n\n.footer .footer-logo .nav_logo {\n    height: 30px;\n    width: auto;\n}\n\n.footer .footer-logo .page-list {\n    display: flex;\n    justify-content: space-between;\n    padding-top: 20px;\n}\n\n\n.footer a .social-icon {\n    font-size: 32px;\n\n}\n\n.footer ul.social-container {\n    display: flex;\n    justify-content: space-between;\n}\n\n.footer ul.social-container a {\n    display: block;\n}\n\n.footer .footer-privacy-policy {\n    font-weight: 500;\n    font-size: 10px;\n    color: var(--color-neutrals-neutrals-1);\n    padding-top: 47px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
