import PageRouter from "./Router/PageRouter";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-toastify/dist/ReactToastify.css";
import { Flip, ToastContainer } from "react-toastify";
import rootReducer from "./Reducer";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import "./App.css";

function App() {
    const store = createStore(rootReducer, compose(applyMiddleware(thunk)));
    return (
        <Provider store={store}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                transition={Flip}
                theme="light"
            />
            <PageRouter />
        </Provider>
    );
}

export default App;
