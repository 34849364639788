import * as constant from "./constant";

const initialState = {
    isLoading: false,
    scheduleList: [],
    blogList: [],
    latestCategoryList: [],
    trendingPlayerLIst: [],
    editorList: [],
    categoryList: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case constant.LOADER: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case constant.SET_SCHEDULE_LIST:
            console.log(`------${action.type}--------`);
            console.log(action.payload, "action.payload");
            return {
                ...state,
                scheduleList: action.payload
            };
        case constant.SET_BLOG_LIST:
            console.log(`------${action.type}--------`);
            console.log(action.payload, "action.payload");
            return {
                ...state,
                blogList: action.payload
            };
        case constant.LATEST_CATEGORY_LIST:
            console.log(`------${action.type}--------`);
            console.log(action.payload, "action.payload");
            return {
                ...state,
                latestCategoryList: action.payload
            };
        case constant.TRENDING_PLAYER_LIST:
            console.log(`------${action.type}--------`);
            console.log(action.payload, "action.payload");
            return {
                ...state,
                trendingPlayerLIst: action.payload
            };
        case constant.EDITOR_LIST:
            console.log(`------${action.type}--------`);
            console.log(action.payload, "action.payload");
            return {
                ...state,
                editorList: action.payload
            };
        case constant.CATEGORY_LIST:
            console.log(`------${action.type}--------`);
            console.log(action.payload, "action.payload");
            return {
                ...state,
                categoryList: action.payload
            };
        default:
            return state;
    }
}