// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ========================================================================== */
/*                                   NavBar                                   */
/* ========================================================================== */
.top_nav {
    background-color: var(--color-primary-blue-5);
}

.top_nav .nav_logo {
    height: 30px;
    width: auto;
}

.top_nav .nav_right .nav_icon {
    height: 18px;
    font-size: 18px;
    color: var(--color-neutrals-neutrals-1);
}

.top_nav .nav-link {
    font-weight: 400;
    font-size: 16px;
    color: var(--color-neutrals-neutrals-1);
}

.top_nav .nav-link:hover {
    color: var(--color-neutrals-neutrals-1);
}

.top_nav .nav-link.active {
    color: red !important;
}

.top_nav .navbar-toggler {
    color: var(--color-neutrals-neutrals-1);
    border-color: var(--color-neutrals-neutrals-1);
}

.top_nav .navbar-toggler:focus {
    box-shadow: none;
    transform: scale(0.8);
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/HeaderStyle.css"],"names":[],"mappings":"AAAA,+EAA+E;AAC/E,+EAA+E;AAC/E,+EAA+E;AAC/E;IACI,6CAA6C;AACjD;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,uCAAuC;AAC3C;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uCAAuC;IACvC,8CAA8C;AAClD;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":["/* ========================================================================== */\n/*                                   NavBar                                   */\n/* ========================================================================== */\n.top_nav {\n    background-color: var(--color-primary-blue-5);\n}\n\n.top_nav .nav_logo {\n    height: 30px;\n    width: auto;\n}\n\n.top_nav .nav_right .nav_icon {\n    height: 18px;\n    font-size: 18px;\n    color: var(--color-neutrals-neutrals-1);\n}\n\n.top_nav .nav-link {\n    font-weight: 400;\n    font-size: 16px;\n    color: var(--color-neutrals-neutrals-1);\n}\n\n.top_nav .nav-link:hover {\n    color: var(--color-neutrals-neutrals-1);\n}\n\n.top_nav .nav-link.active {\n    color: red !important;\n}\n\n.top_nav .navbar-toggler {\n    color: var(--color-neutrals-neutrals-1);\n    border-color: var(--color-neutrals-neutrals-1);\n}\n\n.top_nav .navbar-toggler:focus {\n    box-shadow: none;\n    transform: scale(0.8);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
